import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './Header'
import '../../styles/main.sass'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const scrollRef = useRef(null)

  useEffect(() => {
    import('locomotive-scroll').then(locomotiveModule => {
      // eslint-disable-next-line no-unused-vars, new-cap
      const scroll = new locomotiveModule.default({
        el: scrollRef.current,
        smooth: true,
        smoothMobile: false,
      })

      const newEra = []

      const fade = []
      const spinner = []
      const rotater = []
      const roller = []
      const expander = []

      const paster = []
      const appearer = []
      const fader = []
      const appearerFast = []
      const stagger = []

      scroll.on('scroll', instance => {
        const spinnerSpeed = instance.scroll.y / 10

        newEra.forEach(obj => {
          const { top } = obj.el.getBoundingClientRect()
          let start = 30 + top / 20
          let end = -(top / 20) + 70
          let scaleBg = top * 0.001 + 1.2
          let scaleFg = top * 0.0008 + 1.4
          let translateName = top * 0.8 + 500
          const opacityName = -(top + 50) / 100
          const invertOpacity = (top + 100) / 100

          if (start > 30) {
            start = 30
          }
          if (start < 0) {
            start = 0
          }
          if (end < 70) {
            end = 70
          }
          if (end > 100) {
            end = 100
          }

          if (scaleBg > 1.2) {
            scaleBg = 1.2
          }
          if (scaleBg < 1) {
            scaleBg = 1
          }

          if (scaleFg > 1.4) {
            scaleFg = 1.4
          }
          if (scaleFg < 1) {
            scaleFg = 1
          }

          if (translateName > 300) {
            translateName = 300
          }
          if (translateName < 0) {
            translateName = 0
          }

          obj.el.querySelector(
            '.scale-clip'
          ).style.clipPath = `polygon(${start}% ${start}%, ${end}% ${start}%, ${end}% ${end}%, ${start}% ${end}%)`
          obj.el.querySelector('.scale-bg').style.transform = `scale(${scaleBg})`
          obj.el.querySelector('.scale-fg').style.transform = `scale(${scaleFg})`
          obj.el.querySelector('.fade-name').style.transform = `translateY(${translateName}px)`
          obj.el.querySelector('.fade-name').style.opacity = opacityName - 4
          obj.el.querySelector('.fade-year').style.opacity = opacityName
          obj.el.querySelector('.fade-chapter').style.opacity = invertOpacity
        })

        fade.forEach(obj => {
          const inView = obj.bottom - window.innerHeight * 3
          const position = instance.scroll.y / obj.bottom
          let fader = (position - 0.91) * 16.7

          if (fader < 0.3) {
            fader = 0.3
          }

          if (inView <= instance.scroll.y) {
            obj.el.style.opacity = 1
            obj.el.querySelector('.expander').style.transform = `scale(${fader})`
          } else {
            obj.el.style.opacity = 0
          }
        })

        spinner.forEach(obj => {
          obj.el.querySelector('#fun-svg').style.transform = `rotate(${spinnerSpeed}deg)`
        })

        rotater.forEach(obj => {
          const { top } = obj.el.getBoundingClientRect()
          const dir = obj.el.getAttribute('rotate-dir')
          let slowspin = top / 30

          if (dir === 'left') {
            slowspin = -slowspin
          }
          obj.el.querySelector(
            '.rotate-el'
          ).style.transform = `rotate(${slowspin}deg) translateX(${slowspin}px)`
        })

        roller.forEach(obj => {
          const { top } = obj.el.getBoundingClientRect()
          const translateSpeed = -top * 1.4

          if (top < instance.scroll.y + window.innerHeight) {
            obj.el.querySelector(
              '.roller'
            ).style.transform = `translateX(${translateSpeed}px) rotate(${spinnerSpeed / 0.5}deg)`
            obj.el.querySelector('.wipe-cover').style.transform = `translateX(${translateSpeed}px)`
            obj.el.querySelector('.wipe-cover').style.opacity = `1`
          }
          if (top < -window.innerHeight * 3) {
            obj.el.querySelector('.wipe-cover').style.opacity = `0`
          }
        })

        expander.forEach(obj => {
          const { top } = obj.el.getBoundingClientRect()
          const delay = Number(obj.el.getAttribute('expand-multiply'))
          let minScale = 0.4
          const customScale = Number(obj.el.getAttribute('expand-scale'))

          const loc = obj.el.getAttribute('expand-loc')

          if (customScale) {
            minScale = customScale
          }

          let scale = -top / (100 * delay)

          if (loc === 'center') {
            scale = -top / (500 * delay)
          }

          if (scale > 1.1) {
            scale = 1.1
          }
          if (scale < minScale) {
            scale = minScale
          }

          obj.el.querySelector('.expander').style.transform = `scale(${scale})`
        })

        paster.forEach(obj => {
          const { top } = obj.el.getBoundingClientRect()
          const delay = Number(obj.el.getAttribute('paste-delay')) * window.innerHeight
          const speed = Number(obj.el.getAttribute('paste-speed'))
          let mod = 1

          if (speed !== 0) {
            mod = speed
          }
          let end = -((top + delay) / (15 / mod))
          if (end < 0) {
            end = 0
          }
          if (end > 100) {
            end = 100
          }

          obj.el.querySelector(
            '.paster'
          ).style.clipPath = `polygon(0 0, 100% 0, 100% ${end}%, 0 ${end}%)`
        })

        appearer.forEach(obj => {
          const { top } = obj.el.getBoundingClientRect()
          const delay = Number(obj.el.getAttribute('appear-delay')) * window.innerHeight
          const fast = obj.el.getAttribute('appear-delay')

          let end = -((top + delay) / 400)

          if (end < 0) {
            end = 0
          }
          if (end > 1) {
            end = 1
          }
          if (fast) {
            if (end < 0.5) {
              end = 0
            }
            if (end >= 0.5) {
              end = 1
            }
          }

          obj.el.querySelector('.appearer').style.opacity = end
        })

        fader.forEach(obj => {
          const { top } = obj.el.getBoundingClientRect()
          const delay = Number(obj.el.getAttribute('appear-delay')) * window.innerHeight

          const end = -((top + delay) / 400)

          obj.el.querySelector('.appearer').style.opacity = end
        })

        stagger.forEach(obj => {
          const { top } = obj.el.getBoundingClientRect()

          let scale = top - window.innerHeight / 2 - 200

          if (scale <= 0) {
            scale = 0
          }

          obj.el.style.transform = `translateX(${scale}px)`
        })

        appearerFast.forEach((obj, index) => {
          const { top } = obj.el.getBoundingClientRect()
          const fast = obj.el.getAttribute('appear-delay')

          let end = -top * 3
          if (end < 0) {
            end = 0
          }
          if (end > 1) {
            end = 1
          }
          if (fast) {
            if (end < 0.5) {
              end = 0
            }
            if (end >= 0.5) {
              end = 1
            }
          }

          obj.el.querySelector('.appearer').style.opacity = end
        })
      })

      scroll.on('call', (value, way, obj) => {
        if (value === 'wipe') {
          if (way === 'enter') {
            obj.el.classList.add('active')
          } else {
            obj.el.classList.remove('active')
          }
        }

        if (value === 'img-load') {
          if (way === 'enter') {
            obj.el.classList.add('loaded')
          } else {
            obj.el.classList.remove('loaded')
          }
        }

        if (value === 'spin') {
          if (way === 'enter') {
            spinner.push({
              id: obj.id,
              el: obj.el,
            })
          }
        }

        if (value === 'roll') {
          if (way === 'enter') {
            roller.push({
              id: obj.id,
              el: obj.el,
            })
          }
        }

        switch (value) {
          case 'new-era':
            newEra.push({
              id: obj.id,
              el: obj.el,
              bottom: obj.bottom,
              top: obj.top,
            })
            break
          case 'fade':
            fade.push({
              id: obj.id,
              el: obj.el,
              bottom: obj.bottom,
              top: obj.top,
            })
            break
          case 'expand':
            expander.push({
              id: obj.id,
              el: obj.el,
              bottom: obj.bottom,
              top: obj.top,
            })
            break
          case 'rotate':
            rotater.push({
              id: obj.id,
              el: obj.el,
            })
            break
          case 'paste':
            paster.push({
              id: obj.id,
              el: obj.el,
            })
            break
          case 'appear':
            appearer.push({
              id: obj.id,
              el: obj.el,
            })
            break
          case 'fader':
            fader.push({
              id: obj.id,
              el: obj.el,
            })
            break
          case 'appear-fast':
            appearerFast.push({
              id: obj.id,
              el: obj.el,
            })
            break
          case 'stagger-in':
            stagger.push({
              id: obj.id,
              el: obj.el,
            })
            break
          default:
            break
        }
      })
    })
  }, [])

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="o-scroll" id="js-scroll" ref={scrollRef}>
        {children}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
