import React from 'react'
import PropTypes from 'prop-types'

import Logo from '../../icons/Logo'
import SubLogo from '../../icons/SubLogo'

const Header = ({ siteTitle }) => (
  <header id="header">
    <div>
      <Logo />
      <SubLogo />
      <span className="site-title dn">{siteTitle}</span>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
